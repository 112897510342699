import studentService from "../../services/student.service"


    export const state ={
        students:[],
        data:{
            campus:'',
            school:'',
            department:'',
            program:'',
            level:'',
            year:''
             
          },

    }
    export const actions = {
        fetchStudents({commit}, payload){
            return  studentService.getStudents(payload).then(
                data => {                 
                    commit ('SET_STUDENTS', data);
                    commit ('SET_PAYLOAD', payload);
                    return Promise.resolve(data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },

    }
    export const mutations = {
        SET_STUDENTS(state, students){
            state.students = students
        },
        SET_PAYLOAD(state, payload){
            state.payload = payload
        },
        AssetsFailed(){

        }
    }
    export const getters = {
        students(state){
            return state.students
        },
        payload(state){
            return state.payload
        },
    }


